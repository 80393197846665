<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
  Form(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorCitizenPassportTable',
  components: {
    Form: () => import('./Form')
  },
  data () {
    return {
      headers: [
        { value: 'serial', text: this.$t('serialAndNum'), sortable: false },
        { value: 'issued_by', text: this.$t('passportIssued'), sortable: false },
        { value: 'date_start', text: this.$t('dateIssue'), sortable: false },
        { value: 'date_end', text: this.$t('dateTermination'), sortable: false },
        { value: 'status_document', text: this.$t('status'), sortable: true },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: ({ id }) => this.$router.push({ name: 'passports-citizen-info',
            params: { ...this.$route.params, documentID: id },
            query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.sailorCitizenPassports,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getSailorCitizenPassports']),
    async getData (params) {
      await this.getSailorCitizenPassports({ ...this.$route.params, params })
    }
  }
}
</script>
